.list-group-item .centence{
    padding: 1px 50px;
}
.user{
    font-size: 22px;
}
.handle {
    width: 45px;
    border: 2px solid #ccc;
    background: #fff;
    position: absolute;
    right: 10px;
    top: 7px;
    text-align: center;
}
