.reply{
    margin-top: 0px;
}
li{
    transition: .5s;
    overflow: hidden;
}

.handle {
    width: 40px;
    border: 1px solid #ccc;
    background: #fff;
    position: absolute;
    right: 10px;
    top: 7px;
    text-align: center;
}



